import axios from 'axios';
import { reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import { DisplayType, RequestLogin, ResponseLogin } from '@/shared/types/token';
import useVerifyDevice from '@/composables/useVerifyDevice/useVerifyDevice';
import { ResponsePostHog } from '@/shared/commons';
import { Locale } from '@/shared/types/generic';
import i18n from '@/plugins/i18n/i18n';

const useTokenStore = defineStore('token', () => {
  let settings: ResponseLogin = reactive({
    accessToken: '',
    teamReference: '',
    languageCode: '',
    organizationName: '',
    loginWithoutPin: false,
    publicDisplayReference: '',
  });

  const { isTablet } = useVerifyDevice();
  const isLoading = ref(false);
  const device = isTablet ? 'ipad' : undefined;

  function $reset() {
    settings = {
      accessToken: '',
      teamReference: '',
      languageCode: '',
      organizationName: '',
      publicDisplayLayout: undefined,
      publicDisplayReference: '',
    };
  }

  function getPostHogKey(): Promise<ResponsePostHog> {
    const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/public-displays/configs`;
    return axios.get(apiUrl, {
      headers: {
        'content-type': 'application/json',
        'Public-Display-Authorization': `Bearer ${settings.accessToken}`,
      },
    });
  }

  async function isAuthenticatedWithoutPin(
    organizationName: string,
  ): Promise<void> {
    isLoading.value = true;
    settings.organizationName = organizationName;

    await axios({
      method: 'get',
      headers: {
        'content-type': 'application/json',
      },
      url: `${process.env.VUE_APP_API_BASE_URL}/management/public-displays/authentication/${organizationName}`,
    })
      .then((response) => {
        isLoading.value = false;
        const loginResponse: ResponseLogin = response.data;
        settings.loginWithoutPin = true;
        settings.accessToken = loginResponse.accessToken;
        settings.publicDisplayLayout = loginResponse.publicDisplayLayout;
        settings.teamReference = loginResponse.teamReference;
        settings.languageCode = loginResponse.languageCode;
        settings.publicDisplayReference = loginResponse.publicDisplayReference;
        i18n.global.locale.value = settings.languageCode;
      })
      .catch(() => {
        isLoading.value = false;
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  async function getAuthenticationByDisplayCode(displayCode: string) {
    await axios({
      method: 'get',
      headers: {
        'content-type': 'application/json',
      },
      url: `${process.env.VUE_APP_API_BASE_URL}/management/public-displays/authentication?displayCode=${displayCode}`,
    })
      .then((response) => {
        isLoading.value = false;
        const loginResponse: ResponseLogin = response.data;
        settings.loginWithoutPin = true;
        settings.accessToken = loginResponse.accessToken;
        settings.publicDisplayLayout = loginResponse.publicDisplayLayout;
        settings.teamReference = loginResponse.teamReference;
        settings.languageCode = loginResponse.languageCode;
        settings.publicDisplayReference = loginResponse.publicDisplayReference;
        i18n.global.locale.value = settings.languageCode;
      })
      .catch(() => {
        isLoading.value = false;
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  async function login(organizationName: string, pin?: string): Promise<void> {
    settings.organizationName = organizationName;
    const data: RequestLogin = {
      pin: pin || '',
      organizationName,
      displayType: DisplayType.OPERATOR,
    };

    await axios({
      method: 'post',
      data,
      headers: {
        'content-type': 'application/json',
      },
      url: `${process.env.VUE_APP_API_BASE_URL}/management/public-displays/login`,
      params: { device },
    }).then((response) => {
      const loginResponse: ResponseLogin = response.data;
      settings.accessToken = loginResponse.accessToken;
      settings.loginWithoutPin = false;
      settings.publicDisplayLayout = loginResponse.publicDisplayLayout;
      settings.teamReference = loginResponse.teamReference;
      settings.languageCode = loginResponse.languageCode;
      settings.publicDisplayReference = loginResponse.publicDisplayReference;
      i18n.global.locale.value = loginResponse.languageCode as Locale;
    });
  }

  return {
    $reset,
    settings,
    login,
    getPostHogKey,
    isLoading,
    isAuthenticatedWithoutPin,
    getAuthenticationByDisplayCode,
  };
});

export default useTokenStore;
